import React from "react";
import Topnavbar from "./companes/Top-Navbar/Topnavbar.jsx";
import Navbar from "./companes/Navbar/navbar.jsx";
import Header from "./companes/Header/header.jsx";
import Section from "./companes/Section/Section.jsx";


function App() {
  return (
    <div className="App">
      <Topnavbar/>
      <Navbar />
      <Header />
      <Section />

    </div>
  );
}

export default App;
