import React from "react";
import "./header.css";
import Logo from "../Images/Frame (9).png";

const header = () => {
  return (
    <>
      <header>
        <div className="div_logo">
          <img className="logo" src={Logo} alt="" />
        </div>
        <div className="div_text">
          <h1 className="div_texth1" >
            Explore your <br /> playground.
          </h1>
          <p className="div_textp" >
            Our new Outdoors collection is built to keep you Doing Things when
            the going gets chilly.
          </p>
          <button>Shop — Outdoors</button>
        </div>
      </header>
    </>
  );
};

export default header;
