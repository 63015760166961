import React from "react";
import "./navbar.css";

const navbar = () => {
  return (
    <>
      <header>
        <div className="nav_logo">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <nav>
          <div className="navbar_div">
            <ul className="navbar_ul">
              <li>Women</li>
              <li>Men</li>
              <li>OV Kits</li>
            </ul>
            <div className="navbar_h1">
              <h1> Outdoor Voices </h1>
            </div>
            <div className="navbar_icons">
              <i className="bx bx-search-alt-2"></i>
              <i className="bx bx-user"></i>
              <i className="bx bx-trash-alt"></i>
            </div>
          </div>
        </nav>
      </header>

      <div className="line_1">
        <div className="navbar_h1_1">
          <h1> Outdoor Voices </h1>
        </div>

        <div className="navbar_icons_1">
          <i className="bx bx-search-alt-2"></i>
          <i className="bx bx-user"></i>
          <i className="bx bx-trash-alt"></i>
        </div>

        <ul className="navbar_ull">
          <li className="v">Women</li>
          <li className="v">Men</li>
          <li className="v">OV Kits</li>
        </ul>
      </div>
    </>
  );
};


export default navbar;
