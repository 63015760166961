import React from 'react'
import "./Topnavbar.css"

const Topnavbar = () => {
  return (
    <>
      <h4 className="Topnavbar_title">
        Free Shipping on orders over $75 + Free Returns{" "}
      </h4>
    </>
  );
}

export default Topnavbar

