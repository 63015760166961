import React from "react";
import "./section.css";
import section1 from "../Images/Frame (10).png";
// import section11 from "../Images/Frame (12).png";
import section2 from "../Images/Frame (14).png";
import section3 from "../Images/Frame (15).png";
import section4 from "../Images/Frame (16).png";
import section5 from "../Images/Frame (17).png";
import section55 from "../Images/Frame (18).png";
import section7 from "../Images/Frame (19).png";
import section77 from "../Images/Frame (20).png";

export default function Section() {
  return (
    <>
      <div className="container">
        <div className="sect">
          <h1 className="sect_h1">This just in.</h1>
          <button className="sect_but">Shop — New Arrivals</button>
        </div>

        <section className="section_1_1">
          <div className="section_1">
            <img className="section_2_imgg" src={section1} alt="" />
            <h1 className="section_1h11">Primo Fleece Vest</h1>
            <p className="section_1p">3 colors available.</p>
          </div>
          <div className="section_1">
            <img className="section_2_imgg" src={section1} alt="" />
            <h1 className="section_1h11">Rectrek Zip-Off Pant</h1>
            <p className="section_1p">4 colors available.</p>
          </div>
        </section>

        <section className="section_2">
          <div className="section_2_2">
            <div className="section_2_text">
              <h1 className="section_2_h1">Zip-Offs for all.</h1>
              <p className="section_2_p">
                Our most functional pant now comes in a men’s and women’s
                version.
              </p>
              <button className="sect_but">Shop — Rectrek</button>
            </div>
            <div className="section_2_img">
              <img className="section_2_imgg" src={section2} alt="" />
            </div>
          </div>
        </section>

        <section className="section_2">
          <div className="section_2_2">
            <div className="section_2_img">
              <img className="section_2_imgg" src={section4} alt="" />
            </div>

            <div className="section_4_text">
              <h1 className="section_2_h1">Trail mix or granola?</h1>
              <p className="section_2_p">
                Be the hiking buddy who brings the Snacks.
              </p>
              <button className="sect_but">Shop — Leggings</button>
            </div>
          </div>
        </section>

        <section className="section_2">
          <div className="section_2_2">
            <div className="section_2_text">
              <h1 className="section_2_h1">Get outside.</h1>
              <p className="section_2_p">
                Recreational essentials for your stomping grounds — wherever
                they may be.
              </p>
              <button className="section_3_but">
                Shop — Shoes & Accessories
              </button>
            </div>
            <div className="section_2_img">
              <img className="section_2_imgg" src={section3} alt="" />
            </div>
          </div>
        </section>

        <section className="section_5">
          <div className="section_5_5">
            <img className="section_5_img" src={section55} alt="" />
            <img className="section_5_img" src={section5} alt="" />
          </div>
        </section>

        <section className="section_6">
          <div className="section_6_6">
            <p>
              Outdoor Voices is on a mission to get the world moving. We believe
              freeing fitness from performance starts with having fun and
              generating endorphins. That's why we create high quality products
              for Recreation that make you feel strong, confident, and ready for
            </p>
            <h4>#DoingThings .</h4>
            <div className="section_6_div">
              <p>Our Story</p>
              <i class="bx bx-right-arrow-alt"></i>
            </div>
          </div>
        </section>

        <div className="sect">
          <h1 className="sect_h1">The Recreationalist</h1>
          <button className="sect_but">Read More</button>
        </div>

        <section className="section_1_1">
          <div className="section_1">
            <img className="section_2_imgg" src={section7} alt="" />
            <p className="section_1p">By Caitlin Rounds</p>
            <h1 className="section_1h11">A New Wave For Surf Culture</h1>
          </div>
          <div className="section_1">
            <img className="section_2_imgg" src={section77} alt="" />
            <p className="section_1p">By The Recreationalist</p>
            <h1 className="section_1h11">Playlist: Walk in the Park</h1>
          </div>
        </section>
      </div>
    </>
  );
}
